<template>
    <div id="home-status">
        <h2  is="sui-header" class="dividing">System Status</h2>
        <sui-statistics-group :columns="4">
            <sui-statistic>
                <sui-statistic-value>
                    {{valueTotalCamera}}
                </sui-statistic-value>
                <sui-statistic-label>
                    Camera Feeds
                </sui-statistic-label>
            </sui-statistic>
            <sui-statistic color="green">
                <sui-statistic-value>
                    {{valueCameraOnline}}
                </sui-statistic-value>
                <sui-statistic-label>
                    Camera online
                </sui-statistic-label>
            </sui-statistic>
            <sui-statistic color="violet">
                <sui-statistic-value>
                    {{valueTotalEvent}}
                </sui-statistic-value>
                <sui-statistic-label>
                    Event Detected
                </sui-statistic-label>
            </sui-statistic>
            <sui-statistic color="red">
                <sui-statistic-value>
                    {{valueTotalHelpCount}}
                </sui-statistic-value>
                <sui-statistic-label>
                    Emergency Event
                </sui-statistic-label>
            </sui-statistic>
        </sui-statistics-group>
        <sui-divider clearing hidden></sui-divider>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Status",
        data: function() {
            return {
                valueTotalCamera: 0,
                valueCameraOnline: 0,
                valueTotalEvent: 0,
                valueTotalHelpCount: 0
            }
        },
        created: function() {
            this.getStatistics();
        },
        methods: {
            getStatistics: async function (){
                let response = await axios({
                    method: "POST",
                    url: "/api/camera/statistics",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    data: {
                        token: this.$cookies.get('token'),
                        tokenId: this.$cookies.get('tokenId'),
                    }
                });
                if (response.data) {
                    this.valueTotalCamera= response.data.valueTotalCamera;
                    this.valueCameraOnline= response.data.valueCameraOnline;
                    this.valueTotalEvent= response.data.valueTotalEvent;
                    this.valueTotalHelpCount= response.data.valueTotalHelpCount;
                }
            }
        }
    }
</script>

<style scoped>

</style>
