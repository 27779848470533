<template>
    <div id="event-list">
        <h2  is="sui-header" class="dividing">Detected Events</h2>
        <sui-table celled collapsing selectable compact="">
            <sui-table-header full-width>
                <sui-table-row>
                    <sui-table-header-cell :width="5">
                        Time
                    </sui-table-header-cell>
                    <sui-table-header-cell :width="5">
                        Location
                    </sui-table-header-cell>
                    <sui-table-header-cell :width="5">
                        Event
                    </sui-table-header-cell>
                    <sui-table-header-cell :width="5">
                        Assignee
                    </sui-table-header-cell>
                    <sui-table-header-cell :width="5">
                        Status
                    </sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body>
            </sui-table-body>
        </sui-table>
        <sui-divider clearing hidden></sui-divider>
    </div>
</template>

<script>
    export default {
        name: "EventList"
    }
</script>

<style scoped>

</style>
