<template>
    <div id="dashboard-home">
        <Status />
        <EventList />
        <!--<BookmarkedVideo />-->
    </div>
</template>

<script>

    import Status from "@/views/dashboard/home/Status";
    import EventList from "@/views/dashboard/home/EventList";
    //import BookmarkedVideo from "@/views/dashboard/home/BookmarkedVideo";

    export default {
        name: "DashboardHome",
        components: {
            EventList,
            Status,
            //BookmarkedVideo
        }
    }
</script>

<style scoped>

</style>